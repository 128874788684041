<template>
  <div class="about">
    <div>
      <homeComAbout :src="bgImg"></homeComAbout>
    </div>
    <a-row>
      <div class="aboutUs">
        <div class="about-left">
          <div class="pic">
            <video class="img" 
            src="https://material.smartcatplus.com/publicize-564267.mp4"
            id="video" 
            controls = "true" 
            poster="@/assets/smartcat/videoOff.png" 
            preload="auto" 
            webkit-playsinline="true"  
            playsinline="true" 
            x-webkit-airplay="allow" 
            x5-video-player-type="h5" 
            x5-video-player-fullscreen="true" 
            x5-video-orientation="portraint" 
            style="object-fit:fill"
            ></video>
          </div>
          <img class="rowImg" src="@/assets/smartcat/row.png" alt="">
        </div>
        <div class="about-right">
          <div class="head">
            <span class="homeTitle">
              关于我们
            </span>
          </div>
          <div class="container">
            <p class="item">上海嘿猫嗨购信息技术有限公司(SMARTCAT嘿猫嗨购全球集</p> <br>
            <p class="item">运建站平台) 是一站式全球集运解决方案领先者，致力于为数</p> <br>
            <p class="item">万位海外创业者，中小企业拓展国货出海提供商业机会。依托</p> <br>
            <p class="item">母公司YDH20年物流仓储服务体系,覆盖全球200+国家物流线</p> <br>
            <p class="item">路，领先的集运系统开发团队，为集运商家提供便捷的集运系</p> <br>
            <p class="item">统，全程可视化仓储服务，国际物流服务等一站式集运解决方</p> <br>
            <p class="item">案。</p>
          </div>
        </div>
      </div>
      <div class="mission">
        <div class="container">
          <div class="left">
            <div class="text">
              <div class="mission-company">公司使命</div>
              <span class="span">让国货遍全球</span>
            </div>
            <div class="text">
              <div class="mission-company">公司愿景</div>
              <span class="span">帮助数万位全球创业者轻松做集运</span>
            </div>
          </div>
          <div class="right">
            <img class="img" src="@/assets/smartcat/mission.png" alt="">
          </div>
        </div>
      </div>
      <!-- <div class="saas">
        <div class="background-text">
          <div class="text-item">
            <span>P</span>
          </div>
          <div class="text-item">
            <span>R</span>
          </div>
          <div class="text-item">
            <span>O</span>
          </div>
          <div class="text-item">
            <span>D</span>
          </div>
          <div class="text-item">
            <span>U</span>
          </div>
          <div class="text-item">
            <span>C</span>
          </div>
          <div class="text-item">
            <span>T</span>
          </div>
        </div>
        <div class="textCenter">
          <h2 class="homeTitle">
            了解我们的SaaS产品
          </h2>
          <div class="title-span">全场景全链路的SaaS产品，助力集运服务升级</div>
        </div>
        <div class="duct">
          <div class="item">
            <div class="pic">
              <img class="img" src="@/assets/smartcat/flowmeter.png" alt="">
            </div>
            <span class="span">智能的集运系统</span>
          </div>
          <div class="item">
            <div class="pic">
              <img class="img1" src="@/assets/smartcat/visual.png" alt="">
            </div>
            <span class="span">全球可视化仓储服务</span>
          </div>
          <div class="item">
            <div class="pic">
              <img class="img2" src="@/assets/smartcat/global.png" alt="">
            </div>
            <span class="span">覆盖全球200+国际物流服务线路</span>
          </div>
          <div class="item">
            <div class="pic">
              <img class="img3" src="@/assets/smartcat/accompany.png" alt="">
            </div>
            <span class="span">陪伴式展业支持服务</span>
          </div>
        </div>
      </div> -->
      <div class="introduce">
        <div class="introduceContainer">
          <div class="topTitle">
            <div class="titleItem">
              <div class="top">20年</div>
              <div class="bot">国际物流经验</div>
            </div>
            <div class="titleItem">
              <div class="top">200+</div>
              <div class="bot">路线覆盖国家</div>
            </div>
            <div class="titleItem">
              <div class="top">200+</div>
              <div class="bot">国际物流线</div>
            </div>
          </div>
          <div class="addrIMG">
             <img class="img" src="@/assets/smartcat/addr.svg" alt="">
          </div>
        </div>

      </div>
      <div class="partner">
        <div class="background-text">
          <div class="text-item">
            <span>P</span>
          </div>
          <div class="text-item">
            <span>A</span>
          </div>
          <div class="text-item">
            <span>R</span>
          </div>
          <div class="text-item">
            <span>T</span>
          </div>
          <div class="text-item">
            <span>N</span>
          </div>
          <div class="text-item">
            <span>E</span>
          </div>
          <div class="text-item">
            <span>R</span>
          </div>
          <div class="text-item">
            <span>S</span>
          </div>
        </div>
        <div class="partner-textCenter">
          <h2 class="homeTitle">
            合作伙伴
          </h2>
          <div class="title-span">专业物流20年, 众多合作伙伴</div>
        </div>
        <div class="partner-work">
          <div class="partner-item">
            <div>
              <img class="expressImg" src="@/assets/smartcat/express1.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express2.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express3.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express4.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express5.png" alt="">
            </div>
          </div>
          <div class="partner-item">
            <div>
              <img class="expressImg" src="@/assets/smartcat/express6.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express7.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express8.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express9.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express10.png" alt="">
            </div>
          </div>
          <div class="partner-item">
            <div>
              <img class="expressImg" src="@/assets/smartcat/express11.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express12.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express13.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express14.png" alt="">
            </div>
            <div>
              <img class="expressImg" src="@/assets/smartcat/express15.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import homeComAbout from "../homeComponents/homeComAbout.vue"
export default {
  name: 'homeComIntroduce',
  components: {
    homeComAbout
  },
  data () {
   return {
    bgImg:require('@/assets/smartcat/bannerAbout.png')
   }
  }
}
</script>

<style lang="scss" scoped="scoped">
.aboutUs {
  margin: auto;
  margin-top: 120px;
  width: 1200px;
  height: 450px;
  display: flex;
  background: #f8f8f8;
  border-radius: 10px;
  position: relative;

  .about-left {
    width: 600px;

    .pic {

      .img {
        width: 600px;
        height: 450px;
        border-radius: 10px 0px 0px 10px;
        position: absolute;
        z-index: 999;
      }
    }


    .rowImg {
      position: absolute;
      bottom: -70px;
      left: -70px;
      width: 140px;
      height: 140px;
      z-index: 10;
    }
  }

  .about-right {
    width: 600px;

    .head {
      margin-top: 40px;
      margin-left: 60px;

      .homeTitle {
        display: inline-block;
        height: 23px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 15px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 10px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);
        margin-top: 20px;
        margin-bottom: 50px;

      }
    }

    .small-text {
      margin-top: 30px;
      margin-left: 60px;

      .small {
        width: 158px;
        height: 28px;
        opacity: 1;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .container {
      margin-top: 10px;
      margin-left: 60px;
      width: 500px;
      height: 192px;

      .item {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 8px;
        color: rgba(68, 68, 68, 1);

      }
    }
  }
}

.mission {
  width: 100%;
  background-color: #444;
  margin-top: 100px;

  .container {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 690px;
      height: 300px;

      .text {
        color: #fff;

        .mission-company {
          font-size: 36px;
          letter-spacing: 0px;
          line-height: 50.4px;
          margin-bottom: 10px;
          color: rgba(255, 255, 255, 1);
        }

        .span {
          font-size: 20px;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .right {
      .img {
        width: 400px;
        height: 400px;
        vertical-align: middle;
      }
    }
  }
}

// .saas {
//   position: relative;
//   background-color: #f8f8f8;

//   .background-text {
//     margin: auto;
//     display: flex;
//     justify-content: space-between;
//     width: 1200px;
//     height: 60px;


//     .text-item {
//       height: 101px;
//       font-size: 72px;
//       font-weight: 700;
//       letter-spacing: 0px;
//       line-height: 100.8px;
//       margin-top: 125px;
//       color: #eee;
//     }
//   }

//   .textCenter {
//     position: absolute;
//     left: 50%;
//     top: 32%;
//     transform: translate(-50%, -50%);
//     text-align: center;

//     .homeTitle {
//       width: 342px;
//       margin: auto;
//       font-size: 36px;
//       font-weight: 700;
//       letter-spacing: 0px;
//       line-height: 32px;
//       color: rgba(0, 0, 0, 1);
//       // scroll-padding-top: 125px;
//       background: linear-gradient(to top, #fdd000 4px, #f8f8f8 50%, #f8f8f8 51%, #f8f8f8 100%);

//     }

//     .title-span {
//       margin-top: 20px;
//       font-size: 16px;
//       font-weight: 400;
//       line-height: 22.4px;
//     }
//   }

//   .duct {
//     width: 1200px;
//     margin: auto;
//     display: flex;
//     justify-content: space-around;
//     margin-top: 240px;

//     .item {
//       width: 300px;
//       height: 200px;
//       margin: auto;
//       margin-bottom: 80px;

//       .pic {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 160px;
//         height: 160px;
//         background-color: #ffd000;
//         border-radius: 50%;
//         margin: auto;

//         .img {
//           width: 116px;
//           height: 101px;
//         }

//         .img1 {
//           width: 79px;
//           height: 80px;
//         }

//         .img2 {
//           width: 61px;
//           height: 101px;
//         }

//         .img3 {
//           width: 119px;
//           height: 86px;
//         }
//       }

//       .span {
//         display: block;
//         margin-top: 20px;
//         font-size: 16px;
//         font-weight: 400;
//         letter-spacing: 0px;
//         line-height: 28px;
//         color: rgba(0, 0, 0, 1);
//         text-align: center;

//       }
//     }
//   }
// }
.introduce {
  background-color: #f8f8f8;


.introduceContainer {
  margin: auto;
  width: 1200px;
  .topTitle {
    display: flex;
    justify-content: space-around;
    padding-top: 120px;
    .titleItem {
      .top {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 67.2px;
        color: rgba(253, 208, 0, 1);
      }

      .bot {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 25.2px;
        color: rgba(68, 68, 68, 1);
      }
    }
  }
  .addrIMG {
    margin-top: 76px;
    padding-bottom: 60px;
     .img {
       width: 1200px;
     }
  }
}
}

.partner {
  position: relative;
  margin-bottom: 50px;

  .background-text {
    margin: 120px auto;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 60px;


    .text-item {
      height: 101px;
      font-size: 72px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 100.8px;
      color: #f5f5f5;
    }
  }

  .partner-textCenter {
    position: absolute;
    left: 50%;
    top: 8%;
    transform: translate(-50%, -50%);
    text-align: center;

    .homeTitle {
      width: 146px;
      margin: auto;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 32px;
      color: rgba(0, 0, 0, 1);
      // scroll-padding-top: 125px;
      background: linear-gradient(to top, #fdd000 4px, white 50%, white 51%, white 100%);

    }

    .title-span {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
    }
  }

  .partner-work {
    width: 1200px;
    margin: auto;
    margin-bottom: 200px;

    .partner-item {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      .expressImg {
        width: 200px;
        height: 100px;
      }
    }
  }
}</style>
